"use client";
import { SITE_URL } from "@/env";
import { usePathname, useRouter } from "next/navigation";

const ArticleSocialShare = ({ title }: { title: string }) => {
  const pathname = usePathname();

  const href = SITE_URL + pathname;

  return (
    <ul className="social-wrap flex flex-wrap mb-5">
      <li className="faceboox">
        <a
          href={`https://facebook.com/sharer/sharer.php?u=${href}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Faceboox
        </a>
      </li>
      <li className="group">
        <a
          href={`https://twitter.com/intent/tweet?text=${href}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#263d42] group-hover:bg-[#0c671a]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.05851 5L13.3246 17.3897L4 27.463L6.0986 27.463L14.2623 18.6437L20.8584 27.463L28 27.463L18.2125 14.3764L26.8918 5L24.7932 5L17.2748 13.1225L11.2001 5L4.05851 5ZM7.14467 6.54583L10.4255 6.54583L24.9134 25.917L21.6325 25.917L7.14467 6.54583Z"
                fill="white"
              />
            </svg>
          </span>
        </a>
      </li>{" "}
      <li className="reddit">
        <a
          href={`https://reddit.com/submit?url=${href}&title=${title}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Reddit
        </a>
      </li>
      <li className="linkedin">
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${href}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
      </li>
      <li className="mail">
        <a
          href={`mailto:?subject=Interesting Read&body=${title}: ${href}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Mail
        </a>
      </li>
    </ul>
  );
};
export default ArticleSocialShare;
